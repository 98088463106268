@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:root {
  --primary-color: #015898;
  --primary-color-rgb: 1, 88, 152; // rgba color of #015898 (primary-color)
}

$primary: var(--primary-color);
$primary-rgb: var(--primary-color-rgb);
$mediumGrey: #666;
$white: rgb(255, 255, 255);
$black: #000;
$lightBlack: #333;
$border: #e4e4e4;
$border-radius: 8px;
$error: #f16667;
$success: #31b27c;
$warning: #fdb927;

// export
:export {
  primary: $primary;
}

body {
  letter-spacing: 0.3px;
  // add the font-family to be used(most often) in the project here
  font-family: "Poppins", sans-serif;
  // font-family: "Lato", sans-serif;
  font-size: 14px;
}

@mixin ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $white 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
}

#__lpform_handle,
#__lpform_password {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
b {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: $primary;

  &:hover {
    color: $primary;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cursorPointer {
  cursor: pointer;
}

.table-fixed {
  table-layout: fixed;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

b,
strong {
  font-weight: 600;
}

.themeColor,
.text-primary {
  color: $primary !important;
  cursor: pointer;
}

.text-danger {
  color: $error !important;
}

.text-overflow {
  display: block;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// header
.projectHeader {
  background-color: $white;

  .container-fluid {
    padding-inline: 15px;
  }

  .navbar-brand {
    padding: 0;
    margin: 0;
    cursor: pointer;

    img {
      height: 24px;
    }
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $mediumGrey;
        font-size: 14px;
        margin-inline: 10px;
        position: relative;
        cursor: pointer;
        padding-left: 5px;
        padding-right: 5px;

        &::after {
          background-color: initial;
          bottom: -8px;
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
          transition: 0.2s ease-in;
          width: 100%;
        }

        &:hover,
        &.active {
          color: $primary;

          &::after {
            background-color: $primary;
          }
        }
      }
    }
  }

  .nav-link.active {
    font-weight: 500;
  }

  .bellIcon {
    position: relative;
    margin-right: 15px;
    color: $black;
    span {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 14px;
      height: 14px;
      border-radius: 50px;
      background-color: $primary;
      color: $white;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.sidebarWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  z-index: 9;
  visibility: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba($color: $black, $alpha: 0.3);
    z-index: -1;
    display: none;
  }

  .sidebarInner {
    width: 260px;
    height: 100%;
    background-color: $white;
    transform: translateX(-260px);
    transition: 0.3s ease-in-out;

    .userInfo {
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px dashed $border;
      margin-bottom: 10px;
      cursor: pointer;

      .userAvatar {
        width: 45px;
        height: 45px;
        border: 1px solid $border;
        padding: 5px;
        border-radius: 50px;
        margin-right: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .userName {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: 16px;
        color: $mediumGrey;

        span {
          display: block;
        }

        i {
          margin-left: 5px !important;
        }
      }
    }

    .sidebarMenu {
      overflow-y: auto;
      height: 100%;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary;
        /* color of the scroll thumb */
        border-radius: 500px;
        width: 4px;
        /* roundness of the scroll thumb */
      }

      .list-group {
        padding-inline: 10px;
        padding-bottom: 100px;

        .list-group-item {
          background-color: transparent !important;
          color: $mediumGrey;
          position: relative;
          border: none;
          font-size: 14px;
          padding: 10px 8px;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -4px;
            width: 2px;
            height: 100%;
            background-color: transparent;
          }

          &.active {
            color: $primary;

            &::before {
              background-color: $primary;
            }
          }
        }
      }
    }
  }

  &.show {
    visibility: visible;

    .sidebarInner {
      transform: translateX(0);
    }

    &::before {
      display: block;
    }
  }
}

.toggleIcon {
  background: transparent !important;
  position: relative;
  padding: 0;
  width: 40px;
  height: 36px;
  border-radius: 4px;
  border: none;
  display: none;

  span {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 2px;
    background: #222;
    left: 50%;
    transform: translateX(-50%);
    display: block;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: -8px;
      width: 24px;
      height: 2px;
      background: #222;
    }

    &::after {
      bottom: -8px;
      top: auto;
    }
  }
}

.QuestionQuizWrap {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  &:not(:last-child) {
    border-bottom: 1px dashed $border;
    padding-bottom: 10px;
  }
}

.QuestionQuizWrap label {
  margin-bottom: 0px;
  margin-left: 8px;
  flex: 1;
  word-break: break-all;
  font-size: 13px;
}

// footer
.projectFooter {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-size: 13px;
  color: $mediumGrey;

  ul {
    display: flex;
    align-items: center;
  }

  p {
    margin-bottom: 0;
  }

  .poweredBy {
    display: flex;
  }

  a {
    margin-left: 10px;
    color: $mediumGrey;
    text-decoration: none;
  }

  .lsLink {
    color: #31c5c7;
  }
}

// button
.btn {
  height: 45px;
  font-size: 14px;
  padding: 0 12px;
  line-height: 1;
  border-radius: 6px;

  &.submitBtn {
    width: 100%;
    display: block;
    margin-top: 10px;
  }

  &:focus,
  &:active:focus {
    box-shadow: none !important;
  }
  &.h-auto {
    height: auto;
    padding: 5px 8px;
  }

  &-primary {
    background-color: $primary;
    border-color: $primary;

    &:hover,
    &:focus,
    &:active:focus {
      border-color: rgba($primary-rgb, 0.9);
      background-color: rgba($primary-rgb, 0.9);
    }

    &:disabled {
      border-color: rgba($primary-rgb, 0.8);
      background-color: rgba($primary-rgb, 0.8);
    }
  }

  &-link {
    padding: 0 10px;
    text-decoration: none;
    color: $primary !important;
    height: auto;
    border: none !important;

    &.logout {
      color: $error !important;
    }

    &:hover {
      color: $primary;
      background-color: transparent !important;
    }
    &.remove {
      color: $error !important;
      svg {
        stroke: $error;
        width: 15px;
        height: 15px;
      }
    }
  }

  &-outline-primary {
    border-color: $primary;
    color: $primary;

    &:hover {
      background-color: $primary;
      border-color: $primary;
    }

    &:active:focus,
    &:focus {
      background-color: transparent;
      border-color: $primary;
      color: $primary;
    }

    &:disabled {
      border-color: rgba($primary-rgb, 0.8);
      color: rgba($primary-rgb, 0.8);
    }
  }

  &.btn-reset {
    margin-left: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

// form
input {
  //remove number arrows
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  // auto login field
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
}

.form-label {
  font-size: 14px;
  color: $lightBlack;
  margin-bottom: 5px;
}

.form-control,
.form-select {
  height: 45px;
  font-size: 14px;
  border-radius: 8px;
  border-color: $border;
  box-shadow: none;
  padding: 6px 10px;
  color: $black;

  &:focus {
    box-shadow: none;
    border-color: $primary;
  }
  &:disabled {
    color: $mediumGrey;
  }

  &-sm {
    height: 36px;
  }
  &.h-auto {
    height: auto;
  }
}

.form-select {
  background-position: right 0.35rem center;
  padding-right: 30px !important;
}

.labelWithVariables {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  label {
    margin-bottom: 0;
  }
}

textarea {
  &.form-control {
    height: auto;
  }
}

.input-group {
  border: 1px solid $border;
  border-radius: 8px;
  margin-bottom: 6px;
  transition: border 0.3s ease-in-out;

  &:focus-within {
    border-color: $primary;
  }

  input,
  textarea {
    border-radius: 8px;
    border-color: transparent !important;
    padding-left: 10px;

    ~ .input-group-text {
      background-color: $white;
      border: none;
      color: $mediumGrey;
      height: auto;
      font-size: 16px;
      border-radius: 8px;
    }
  }
}

.form-check {
  &:not(.form-switch) {
    .form-check-input {
      margin-top: 3px;
      height: 14px !important;
      width: 14px !important;
      &:checked {
        background-color: $primary;
        border-color: $primary;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  .form-check-label {
    user-select: none;
  }
}

// react quill/ rich editor
.quill {
  background-color: $white;

  .ql-toolbar {
    border-color: $border !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-color: $border !important;
    min-height: 100px;
  }
}

.form-error {
  font-size: 12px;
  color: $error;
}

// react select
.react-select-container {
  .react-select__control {
    border-radius: 8px;
    border-color: $border;
    height: 45px;
    flex-wrap: nowrap;

    &:hover {
      border-color: $border;
    }

    .react-select__placeholder {
      color: $black !important;
    }

    .react-select__input-container {
      .react-select__input {
        width: max-content !important;
      }
    }

    .react-select__indicators {
      min-width: 73px !important;
      justify-content: flex-end;
      .react-select__dropdown-indicator {
        svg {
          width: 20px;
          height: 20px;
          stroke-width: 0.1px;
          color: rgba($black, 0.5);
        }
      }
    }
  }

  .css-1pahdxg-control {
    min-height: 45px;
    border-color: $primary !important;
    box-shadow: none;
  }

  .react-select__menu {
    overflow: hidden;
    box-shadow: -2px 0px 12px 0px rgba(0, 0, 0, 0.1);

    .react-select__menu-list {
      max-height: 120px;
    }
  }

  .css-1fdsijx-ValueContainer {
    padding-left: 0px !important;
  }
  .css-1dimb5e-singleValue {
    margin-left: 10px !important;
  }
}

// pagination
.paginationWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 12px 0 13px;
  padding: 0 12px;
  font-size: 12px;
  color: $mediumGrey;

  .form-select {
    height: auto;
    width: 80px;
  }
}

.lineClimp {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 350px;
  min-width: 200px;
  color: rgba($black, 0.8);
  overflow: hidden;
  display: -webkit-box;
  margin-bottom: 5px;
}

.pagination {
  margin: 0;

  li {
    margin: 0;

    a {
      font-size: 13px;
      color: $mediumGrey !important;
      height: 28px !important;
      width: 28px !important;
      border-radius: 50px;
      margin-left: 4px;
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba($black, 0.07);
      box-shadow: none !important;
      background-color: transparent !important;
      transition: 0.2s ease-in-out;

      &:hover {
        background-color: $primary !important;
        color: $white !important;
      }

      i {
        font-size: 12px !important;
      }

      &[aria-disabled="true"] {
        cursor: not-allowed;

        &:hover {
          background-color: unset !important;
          color: currentColor !important;
        }

        i {
          color: rgba($black, 0.5);
        }
      }
    }

    &.selected {
      a {
        border: 1px solid $primary;
        background-color: $primary;
        color: $white !important;
      }
    }

    // &:first-child,
    // &:last-child {
    //   a {
    //     font-size: 20px;
    //   }
    // }

    &:first-child,
    &:last-child {
      a {
        border-radius: 50px !important;
        margin: 0;
      }
    }

    &.disabled {
      pointer-events: none;
    }

    &.active {
      a {
        background-color: $primary !important;
        color: white !important;
      }
    }
  }
}

// auth pages
.authPgWrapper {
  overflow-y: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  padding: 25px 15px 12px;

  .authFormWrap {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .companyLogo {
      display: block;
      width: auto;
      height: 38px;
      margin: 0 auto 40px;
    }

    h2 {
      text-align: center;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 22px;
      text-transform: uppercase;
    }

    .authForm {
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
      background-color: $white;
      padding: 20px;
      border: 1px solid rgba($border, 0.5);
      border-radius: 8px;

      .input-group > .form-control:focus + .input-group-text i {
        color: $primary;
      }
    }
  }

  .authFooter {
    width: 100%;
    padding: 0;
  }

  .forgotPassword {
    color: $mediumGrey;
    font-size: 12px;
    text-align: right;
    display: block;

    &:hover {
      color: $primary;
    }
  }
}

.mainWrapper {
  background-color: #f8f8f8;
  width: 100%;
  min-height: 100vh;

  .innerWrapper {
    min-height: calc(100vh - 95px);
    padding: 30px 15px 15px;
  }
}

// page title
.pgTitleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  h2 {
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: normal;
    font-weight: 600;
  }

  .backBtn {
    height: 22px;
    color: $lightBlack;
    width: 22px;
    padding: 0;
    transition: all 0.3s;
    font-size: 14px;
    margin: -1px 10px 0 0;

    i {
      margin-left: -1px;
    }

    &:hover {
      background-color: rgba($black, 0.1);
      transition: all 0.3s;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      height: auto;
      padding: 6px 8px;
      font-size: 12px;

      .plusIcon {
        display: none;
      }
    }
  }

  .form-control,
  .form-select {
    height: 38px;
  }
}

.filterBarIcon {
  display: none;
}

// filter
.filterWrapper {
  background-color: $white;
  border-radius: 10px;
  padding: 15px 15px 0;
  display: flex;
  align-items: center;
  border: 1px solid rgba($black, 0.05);
  margin-bottom: 22px;

  .filterIcon {
    font-size: 15px;
    color: $lightBlack;
  }

  .filterForm {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    padding-left: 13px;
    margin-left: 10px;

    &::before {
      content: "";
      height: 70%;
      width: 1px;
      background-color: rgba($black, 0.06);
      position: absolute;
      left: 0;
      top: 15%;
    }

    .formGroup {
      margin-bottom: 15px;
      padding: 0 10px;

      &.searchBar {
        max-width: 250px;
      }

      .input-group input ~ .input-group-text {
        font-size: 14px;
      }

      &.multiSelect {
        width: 350px;
      }
    }

    // searchbox container
    // .searchBoxContainer {
    //   display: flex;
    //   align-items: flex-end;

    //   .btn-eraser {
    //     height: 47px;
    //     padding: 13px 18px;
    //     border: none !important;
    //     background-color: $error !important;
    //     margin: 15px 0 15px 15px !important;
    //   }
    // }
  }

  .input-group {
    margin-bottom: 0;
  }

  .select__control {
    min-width: 130px;
  }
  .importIcon {
    margin-left: auto;
    svg {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
}

// daterange
.DateRangePicker {
  width: 100%;
  height: 45px;
  max-width: 250px;
  display: block !important;

  > div {
    width: 100%;
    height: 100%;
  }

  .DateRangePickerInput {
    border-radius: 4px;
    border-color: $border;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .DateInput {
      height: 100%;
      border-radius: $border-radius;
      flex: 1;

      input {
        height: 100%;
        font-size: 14px;
        border-radius: $border-radius;
        position: relative;
        font-weight: 400;

        &.DateInput_input__focused {
          border-color: $primary;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &::placeholder {
          color: black;
        }
      }
    }
  }

  .DayPicker,
  .DayPicker > div > div {
    width: 100% !important;
  }

  // .DayPicker_transitionContainer {
  //   width: 370px !important;
  //   height: 420px !important;
  // }

  // .DayPicker_weekHeader {
  //   width: 100%;

  //   ul {
  //     display: flex;
  //     justify-content: space-between;
  //   }
  // }

  .CalendarMonth_caption {
    padding-bottom: 60px;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: rgba($primary-rgb, 0.1);
    border: 1px double rgba($primary-rgb, 0.1);
  }

  .CalendarDay__selected_span {
    background: rgba($primary-rgb, 0.3);
    border: 1px double rgba($primary-rgb, 0.3);
  }
}

// Spinner
.spinner-border {
  width: 20px !important;
  height: 20px !important;
  border-width: 2px;
  border-color: $primary;
  border-right-color: transparent;
}

// multi select (react select)
.select__control {
  height: 45px;
  border-radius: 8px !important;
  border-color: $border !important;

  .select__placeholder {
    font-size: 14px;
    color: $black;
    font-weight: 400;
  }

  .select__indicator {
    padding-left: 2px;
    padding-right: 3px;

    svg {
      color: #606060;
      width: 18px;
    }
  }

  .select__indicator-separator {
    background-color: $border;
  }

  .select__value-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.select__control--is-focused {
  border-color: $primary !important;
  box-shadow: none !important;
}

// table
.table {
  thead {
    tr {
      th {
        font-weight: 500;
        background-color: $white !important;
        border-bottom-color: #f5f5f5;
        padding: 14px 10px !important;
        color: $lightBlack;
        font-size: 13px;
        vertical-align: baseline;
        white-space: nowrap;

        &:first-child {
          border-top-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom-color: #f5f5f5;
        color: $mediumGrey;
        padding: 8px 10px !important;
        font-size: 12px;
        vertical-align: middle;
        word-break: break-all;
        // word-break: break-all;

        .form-select {
          padding-block: 3px !important;
          min-width: 100px;
          width: 170px;
        }

        .btn {
          height: 28px;
          padding: 4px 6px;
          font-size: 12px;
          color: $mediumGrey;
          border: 1px solid $border;

          &:hover {
            color: $black;
            background-color: rgba($black, 0.06);
            border-color: rgba($black, 0.08);
          }
        }

        svg {
          width: 14px;
          height: 14px;
        }

        .tableUserInfo {
          display: flex;
          flex-wrap: wrap;

          h2 {
            font-size: 12px;
            color: $primary;
            cursor: pointer;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            max-width: 200px;
            min-width: 100px;
            display: -webkit-box;
            line-height: 1.4;
          }

          .userContact {
            width: 100%;
            color: $black;
            display: flex;

            i {
              margin-right: 10px;
            }
          }
        }
        .topicTitle {
          width: 150px;
          cursor: pointer;
          color: $primary;
          font-weight: 500;
        }
      }
    }
  }
  .form-check {
    .form-check-input {
      margin-top: 10px !important;
      margin-bottom: 0px !important;
    }
  }

  .form-control,
  .form-select {
    height: 28px;
    padding: 6px;
    font-size: 12px;
  }

  .form-select {
    padding-right: 24px;
    border-radius: 6px;
  }

  textarea {
    min-height: 0 !important;
    line-height: 1;
  }
}

// main content
.main_content_wrapper {
  background-color: $white;
  padding: 15px;
  border-radius: 8px;
}

// tabs
.nav {
  &.nav-pills {
    position: relative;
    z-index: 1;
    &.mobileTabs {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-bottom: 10px;
    }

    .nav-item {
      .nav-link {
        cursor: pointer;
        background-color: transparent;
        color: $mediumGrey;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        white-space: nowrap;

        &.active {
          color: $primary;
          border-bottom-color: $primary;
        }
      }
    }

    &:after {
      border-bottom: 1px solid $border;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;
      z-index: -1;
    }
  }
}

.personalWebsiteSetps {
  margin-top: 40px !important;
  position: relative;
  overflow-x: auto;

  .nav {
    width: calc(100% - 230px);
    pointer-events: none;
    margin: auto;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    &::after {
      display: none;
    }

    .nav-item {
      flex: 1;

      .nav-link {
        font-weight: 400;
        margin-right: 0 !important;
        position: relative;
        border: none !important;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 25px;
          left: -50%;
          width: 100%;
          height: 3px;
          background-color: #e7eaf3;
          z-index: -1;
        }

        .stepCount {
          width: 40px;
          height: 40px;
          margin: auto;
          border-radius: 50px;
          background-color: #e7eaf3;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 8px;
          color: var($black);
          font-size: 14px;

          &.success {
            background-color: $success !important;
            box-shadow: 0 0 10px 2px rgba($success, 0.4);
          }
          svg {
            width: 20px;
            height: 20px;
            stroke: $white;
          }
        }

        &.active {
          color: $black !important;

          .stepCount {
            background-color: $primary;
            color: $white;
          }

          &::after {
            animation: movingLine 0.5s ease-in-out 1 alternate backwards;
            background-color: $success;
          }
        }

        span {
          display: block;
          text-align: center;
          font-size: 12px;
          white-space: nowrap;

          &.stepTitle {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      &:first-child {
        .nav-link {
          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }

  @keyframes movingLine {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
}

.tab-content {
  padding: 15px 10px 10px;

  .tab-pane {
    .paginationWrap {
      margin-bottom: 0 !important;
    }
  }
}

// modal
.modal {
  .modal-dialog {
    &.message-template {
      .logTd {
        color: $primary;
        cursor: pointer;
        display: inline-flex;

        p {
          padding-right: 15px;
          margin-bottom: 0;
        }
      }
    }
  }

  .modal-header {
    padding: 0.8rem 1rem 0.6rem;
    border-bottom-color: rgba($black, 0.06);

    .btn-close {
      box-shadow: none !important;
    }
  }

  .modal-title {
    font-size: 16px;
    font-weight: 600;
  }

  .modal-footer {
    border-top-color: rgba($black, 0.06);
    padding: 0.62rem 1rem 0.6rem;

    button {
      margin: 0;
      height: 35px;
      margin-left: 10px;
    }

    .closeBtn {
      color: $mediumGrey;
    }
  }

  .modal-body {
    padding-bottom: 30px;

    .OrganizationLeader {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        margin-right: 10px;
        color: $black;
        font-weight: 600;
      }
    }
  }

  .modal-dialog.overflow-visible {
    .modal-content,
    .modal-body {
      overflow: visible;
    }
  }
}

// popover
.popover {
  border-color: $primary;

  .popover-header {
    border-bottom-color: $primary;
    background-color: $white;
    font-size: 14px;
    padding: 11px 12px 9px;
    color: $black;
  }

  .popover-body {
    color: $mediumGrey;
    font-size: 12px;
    padding: 12px;

    p {
      margin-bottom: 0;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding-top: 10px;
      width: 150px;
      text-align: left;
      font-weight: 500;

      &:not(:last-child) {
        border-bottom: 1px dashed $border;
        padding-bottom: 10px;
      }
    }
  }
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  border-right-color: $primary;
}

// =========

.priceInputWrap {
  margin-bottom: 0;
  height: 45px;

  .input-group-text {
    border: 0;
    height: 43px;
    background-color: $primary;
    color: $white;
    font-size: 14px;
  }

  input {
    height: 43px;
  }
}

.topicVideo {
  .disabledSeeking {
    video::-webkit-media-controls-timeline {
      pointer-events: none;
    }
  }
}

// upload progress css
#mediaQueueViewerWrapper {
  position: fixed;
  right: 25px;
  bottom: 60px;
  z-index: 999;
  height: 50px;
  background-color: $white;
  border: 1px solid $primary;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  #uploadeProgressHeader {
    display: flex;
    color: $primary;
    font-size: 14px;
  }
}

// upload

input[type="file"] {
  display: none;
}
// single upload file
.uploadFile {
  width: 120px;
  height: 120px;
  border: 1px dashed $border;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 2px;
  svg {
    width: 40px;
    height: 40px;
  }
  .uploadedFile {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
    .deleteIcon {
      cursor: pointer;
      position: absolute;
      top: 6px;
      right: 6px;
      width: 20px;
      height: 20px;
      background-color: $error;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      svg {
        width: 14px;
        height: 14px;
        stroke: $white;
      }
    }
  }
}

.topicUploadWrapper {
  border: 1px solid $border;
  padding: 10px;
  border-radius: 8px;
}
.topicUploadWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .uploadWrap {
    flex: 1 0 200px;
    flex-grow: 1;
    cursor: pointer;
    padding: 30px 15px;
    border: 1px dashed $border;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    svg {
      width: 40px;
      height: 40px;
    }
    span {
      margin-top: 10px;
      display: block;
      font-size: 12px;
      color: $mediumGrey;
    }
  }
}

.uploadBody {
  display: flex;
  justify-content: flex-start;
  gap: 15px;

  h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .UploadContent {
    margin-bottom: 15px;
    overflow: auto;
    position: relative;
    padding-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .uploadItem {
      width: 200px;
      height: 200px;
      border: 1px dashed $border;
      padding: 2px;
      border-radius: 2px;
      position: relative;
      img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
      .deleteIcon {
        cursor: pointer;
        position: absolute;
        top: 6px;
        right: 6px;
        width: 20px;
        height: 20px;
        background-color: $error;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        svg {
          width: 14px;
          height: 14px;
          stroke: $white;
        }
      }
    }
  }
}

.docsPdf {
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  table {
    svg {
      stroke: $error;
      margin-right: 5px;
    }
  }
}

.rdt {
  .rdtPicker {
    width: 220px;
    min-width: inherit;
  }
  .right + .rdtPicker {
    right: 0;
  }
}
// tooltip
.tooltip-inner {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.listInTooltip {
  li {
    background-color: transparent;
    border-radius: 0;
    text-align: left;
    color: $white;
    padding-left: 1px;
    padding-right: 1px;
    padding: 6px 1px;
    justify-content: space-between;
    display: flex;
    align-items: center;

    .nameInitial {
      margin-left: 5px;
    }
  }
}

// full page loader
.fullPgLoaderWrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  opacity: 0.95;
  background: $white;

  .loaderWrap {
    width: 250px;
    height: 200px;
    text-align: center;

    div {
      justify-content: center;
    }

    p {
      font-size: 16px;
      margin-top: 10px;
      color: $mediumGrey;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.tableWrapper,
.tableInCardWrap {
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0rem 0.1525rem 0.3125rem 0rem rgba(82, 63, 105, 0.05%);
  overflow: hidden;
  margin-bottom: 20px;

  .table {
    margin-bottom: 0;
  }
}

.tableInCardWrap {
  padding: 10px;
  padding-bottom: 0;
  margin-bottom: 25px;

  .tableWrapper {
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
  }

  .tableCardHeader {
    border-bottom: 1px solid rgba($black, 0.05);
    font-size: 18px;
    font-weight: 500;
    color: $black;
    padding: 2px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 16px;
      margin-bottom: 0;
      font-weight: 500;
    }
    h6 {
      font-size: 12px;
      font-weight: 400;
      text-align: right;
      margin: 0;
      color: $mediumGrey;
      span {
        margin-left: 3px;
      }
    }

    .btn {
      background-color: $primary;
      border: 1px solid $primary;
      height: 34px;
      line-height: 32px;
    }
  }
}

.dateRangeWithDropDown {
  display: flex;
  border: 1px solid $border;
  border-radius: 8px;
  height: 45px;

  .form-select,
  .DateRangePicker .DateRangePickerInput {
    border: 0;
    height: 43px;
  }

  .DateRangePicker {
    .DateRangePickerInput {
      border-left: 1px solid $border;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .DateInput {
        width: 100px;
      }
    }
  }
}

.contentWrapper {
  padding: 20px 15px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0rem 0.1525rem 0.3125rem 0rem rgba(82, 63, 105, 0.05%);
}

.nameInitial {
  border: 1px solid rgba($black, 0.1);
  border-radius: 5px;
  width: 32px;
  height: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #f8f9fa;
  color: $mediumGrey;
}

.billingSummaryWrap {
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0rem 0.1525rem 0.3125rem 0rem rgba(82, 63, 105, 0.05%);
  margin-bottom: 25px;
  padding: 10px;

  .cardHeader {
    border-bottom: 1px solid rgba($black, 0.05);
    font-size: 20px;
    font-weight: 500;
    color: $black;
    padding: 3px 10px 10px;
  }

  .billingSummary {
    padding-top: 15px;
    padding-bottom: 15px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        background-color: rgba($primary-rgb, 0.1);
        border-radius: 6px;
        flex: 1 1 30.33%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        margin: 10px;
        position: relative;

        h3 {
          font-size: 12px;
        }

        h2 {
          font-size: 14px;
          font-weight: 500;
        }

        .empIcon {
          width: 50px;
          height: 50px;
          background-color: $white;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 30px;
            height: 30px;
            fill: $primary;
          }
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 4px;
          height: 100%;
        }

        &:nth-child(1) {
          &::before {
            background-color: #ff9b00;
          }
        }

        &:nth-child(2) {
          &::before {
            background-color: #49b2ff;
          }
        }

        &:nth-child(3) {
          &::before {
            background-color: #1546ff;
          }
        }

        &:nth-child(4) {
          &::before {
            background-color: #1a6b93;
          }
        }

        &:nth-child(5) {
          &::before {
            background-color: #9c2ac9;
          }
        }

        &:nth-child(6) {
          &::before {
            background-color: #7f0b65;
          }
        }
      }
    }

    .input-group {
      padding: 8px;
      margin-bottom: 0 !important;

      > * {
        height: auto !important;
        padding: 0 !important;
        line-height: 1 !important;
        background: transparent !important;
        border: none !important;
      }

      input {
        height: 20px !important;
        min-height: 0 !important;
        padding-inline: 5px !important;
      }
    }
  }
}

.customUploadFile {
  position: relative;

  label {
    background: #eaecef;
    position: absolute;
    top: 1px;
    left: 1px;
    height: 43px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 0 10.5px;
    line-height: 43px;
  }

  input {
    padding-top: 11px;
  }
}

.rdtPicker {
  font-family: "Poppins", sans-serif;

  th.rdtSwitch,
  .dow {
    font-weight: 600;
  }

  td.rdtActive,
  td.rdtActive:hover {
    background-color: $primary !important;
    border-radius: 5px;
  }
}

.paymentDate {
  .rdtPicker {
    right: 0;
  }
}

.cardWrap {
  padding: 10px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0rem 0.1525rem 0.3125rem 0rem rgba(82, 63, 105, 0.05%);
  margin-bottom: 20px;

  .cardHeader {
    border-bottom: 1px solid rgba($black, 0.05);
    font-size: 18px;
    font-weight: 500;
    color: $black;
    padding: 3px 10px 10px;
  }

  .cardBody {
    padding: 18px 10px 5px;
  }
}

.dropdown-toggle {
  background: $white !important;
  border-color: $primary !important;
  color: $primary !important;
  height: 30px;
  font-size: 14px;
}

.dropdown-menu {
  border-color: rgba($black, 0.08);
  top: 100% !important;
  right: 0 !important;
  // left: -100% !important;
  transform: none !important;
  height: 170px;
  max-height: 170px;
  overflow: auto;
  box-shadow: -2px 0px 12px 0px rgba(0, 0, 0, 0.1);

  .dropdown-item {
    font-size: 14px;
    color: $lightBlack;
    padding-left: 10px;
    padding-right: 10px;

    &:active {
      background: transparent !important;
    }
  }
}

.accordion {
  .accordion-item {
    .accordion-header {
      button {
        box-shadow: none;
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.customAccordionHead {
  border: 1px solid rgba($black, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  transition: all 0.3s;
  cursor: pointer;

  div {
    flex: 1;
    padding: 10px;
  }

  label,
  .btn {
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 0;
    cursor: pointer;
    z-index: 99;
    margin-left: auto;
    margin-bottom: -1px;
  }

  .toggleAccordion {
    font-size: 14px;
    color: $mediumGrey;
    cursor: pointer;
    transition: all 0.3s;
    margin: 2px 10px 0 18px;
  }
}

.customAccordionBody {
  padding: 7px 10px 10px;
  font-size: 14px;
  margin-bottom: 15px;
}

.customAccordionHeadActive {
  background-color: $primary;
  border-color: $primary;
  color: $white;
  transition: all 0.3s;

  i {
    color: $white !important;
  }

  .toggleAccordion {
    transform: rotate(180deg);
    transition: all 0.3s;
    margin-top: -2px;
  }
}

// Questionnaire
.questionnaire {
  .customAccordionHead {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    margin: 0 !important;
    border-radius: 0 !important;
    background: transparent !important;
    border: 0 !important;

    i {
      color: #666 !important;
    }
  }

  .customAccordionBody {
    padding-top: 18px;
    border-top: 1px solid rgba($black, 0.1);
    margin-bottom: 0;
  }
}

.quesAnsWrap {
  margin-top: 5px;
  color: $mediumGrey;

  h5 {
    font-size: 14px;
    font-weight: 500;
    color: $black;
    margin-bottom: 10px;
  }

  textarea {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.quizQuestion {
  display: flex;
  align-items: flex-start;
  .form-check-input {
    margin-right: 5px;
    margin-top: 12px;
  }
  .form-check-input:checked {
    background-color: $success;
    border-color: $success;
  }
  .form-control {
    flex: 1;
  }

  button {
    margin-top: 12px;
  }
}

.attendeesList {
  border: 1px solid $border;
  border-radius: 8px;
  padding: 4px 12px 0;
  margin-top: 2px;
}

.meetingsAttachment {
  li {
    padding: 5px 1px;
    border: 0;
    color: $mediumGrey;
    font-size: 12px;
  }
}

.summaryAttachment {
  li {
    padding: 10px 0;
    border: 0;
    margin-bottom: 0;
    border-bottom: 1px solid rgba($black, 0.07);
    border-radius: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border-bottom: 0;
    }

    .form-check {
      flex: 1;
      margin-right: 10px;
    }

    .form-check,
    label {
      margin-bottom: 0;
    }

    .btn {
      height: 28px;
      font-size: 14px;
      border: 1px solid transparent;
      border-radius: 50%;
      width: 28px;
      padding: 0;
      line-height: 26px;
      transition: all 0.3s;

      &:hover {
        border-color: $error;
        transition: all 0.3s;
      }
    }
  }
}

.editBtn {
  height: 28px;
  font-size: 12px;
  border: 1px solid $primary !important;
  border-radius: 50%;
  width: 28px;
  padding: 0;
  line-height: 26px;
}

// status Update
.statusList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  overflow-x: auto;

  li {
    flex: 1 0 200px;
    position: relative;
    margin: auto;

    svg {
      width: 18px;
      height: 18px;
    }

    span {
      display: block;
      font-size: 12px;
      z-index: 2;
      width: 120px;

      &.status {
        position: absolute;
        color: rgba($black, 0.3);
        top: 0;
      }

      &.count {
        width: 30px;
        height: 30px;
        background-color: rgba($black, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        font-size: 16px;
        color: $white;
        font-weight: 600;
        z-index: 2;
        margin: 50px 0;
        position: relative;
      }

      &.date {
        position: absolute;
        bottom: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background-color: $border;
      z-index: 1;
    }

    &:last-child {
      &::before {
        display: none;
      }
    }

    &.active {
      span {
        &.status {
          color: $lightBlack;
        }

        &.count {
          background-color: $primary;
        }
      }
    }

    &.previousActive {
      span {
        &.count {
          background-color: #fbc658;
        }
      }
    }
  }
}

// Box Container
.box-container {
  background: #fff;

  .input-group {
    &,
    &:focus-within,
    &:focus {
      box-shadow: none !important;
      border: 1px solid #e4e4e4;
    }

    &-text {
      font-size: 14px;
      padding: 4px 8px;
      border: 0;
      border-radius: 8px;
      background: #e9ecef80;

      .form-select {
        height: auto !important;
        padding: 0 10px 0 0 !important;
      }
    }
  }
}

// Lead details -> Documents card
.lead-documents {
  tbody {
    td {
      ul {
        margin-left: 8px;
        counter-reset: lead-documents;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .folderName {
            text-transform: capitalize;
          }

          .fileName {
            margin-bottom: 0;
            font-size: 12px;

            &::before {
              counter-increment: lead-documents;
              content: counter(lead-documents) ".  ";
            }
          }

          textarea {
            width: 40%;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        background-color: transparent !important;
        padding-inline: 7px !important;
      }
    }
  }
}

// Questionnaire
.authPgWrapper.affiliate-questionnaire {
  .authFormWrap {
    .form-title {
      margin-top: 20px;
    }

    .authForm {
      margin: 10px auto;
      max-width: 800px;
      display: flex;
      flex-direction: column;
    }
  }
}

// Alert
.alert-dismissible {
  padding: 12px 10px !important;
  line-height: 1;

  .btn-close {
    top: 50%;
    right: 10px;
    padding: 5px;
    transform: translateY(-50%);
    box-shadow: none !important;
  }
}

// Lead Notes Modal
.notes {
  .modal-content {
    max-height: 600px;
    overflow-y: auto;
  }

  // Add Notes
  .notes-add {
    textarea {
      padding-inline: 9px;
    }

    button {
      display: block;
      height: auto;
      padding: 6px 18px;
    }
  }

  .notesCard {
    margin-top: 20px;
    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      .card-title {
        margin-bottom: 0;
        span {
          font-size: 12px;
          &.title {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      .action {
        button {
          + button {
            padding-right: 0;
          }
        }
      }
    }
    .card-body {
      padding: 10px;
      p {
        margin-bottom: 0;
        font-size: 13px;
        line-height: 1.6;
      }
    }
  }
}

.nodata {
  margin: 20px auto;
  text-align: center;
  img {
    height: 50px;
  }
  p {
    margin-top: 10px;
    font-size: 13px;
  }
}

// Personal Info
.personalInfo {
  .heading {
    font-weight: 600;
  }

  .contactInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    ul {
      li {
        font-size: 13px;
        padding-top: 6px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:not(:last-child) {
          padding-bottom: 6px;
        }

        i {
          width: 14px;
          font-size: 14px;
          margin-right: 10px;
        }

        img {
          width: 14px;
          margin-right: 10px;

          &.telegramIcon {
            width: 20px;
          }
        }

        .value {
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-width: 190px;
          display: -webkit-box;
          word-break: break-all;
        }
      }
    }
  }
}

.completionStep {
  text-align: center;
  ul {
    margin-bottom: 15px;
    li {
      border-bottom: 1px dashed $border;
      padding: 10px 0;
      &:first-child {
        padding-top: 0;
      }
    }
  }

  input {
    margin-right: 5px;
  }
  h4 {
    text-align: center;
    padding-top: 40px;
  }
}

// DocSignedStatus
.docSignStatus {
  min-height: calc(100vh - 35px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;

  img {
    width: 45px;
  }

  .title {
    font-size: 26px;
    font-weight: 700;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin-bottom: 7px;
    font-size: 16px;
  }
}

.acceptAgreementBox {
  display: flex;
  gap: 20px;
  flex-direction: column;

  .sub-title {
    line-height: 1.6;
  }
}

// Sweet Alert 2
.swal2-container {
  // Popup
  .swal2-popup {
    max-width: 450px;
    padding: 20px !important;

    // Icon
    .swal2-icon {
      margin-top: 0;
      width: 80px;
      height: 80px;

      .swal2-icon-content {
        font-size: 50px;
      }
    }

    // Title
    .swal2-title {
      color: $black;
      font-size: 22px;
      padding-inline: 0 !important;
    }

    // Text
    .swal2-html-container {
      font-size: 15px;
      margin-inline: 0 !important;
    }

    // Buttons
    .swal2-styled {
      padding: 7px 16px;
      font-size: 14px;
      margin-block: 0 !important;
      box-shadow: none !important;
    }

    .swal2-confirm {
      background-color: $primary !important;
    }
  }
}

.questionnaireVideo {
  display: flex;
  flex-direction: column;
  align-items: center;

  video {
    width: 100%;
    border-radius: 10px;
  }

  p {
    font-size: 20px;
    text-align: center;
    margin-top: 15px;

    img {
      width: 22px;
      margin-right: 7px;
    }
  }
}

#canv {
  height: 42px;
  border-radius: 10px;
  margin-left: 10px;
}

.calendly-inline-widget {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 800px !important;
}

// Analytics Card
.dashboard-analytics {
  position: sticky;
  top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .analyticsCard {
    flex: 1 0 200px;
    flex-shrink: 0;
    flex-grow: 1;
    border-color: rgba($border, 0.3);
    .card-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h2 {
      font-size: 12px;
      color: rgba($black, 0.6);
      font-weight: 400;
      margin-bottom: 0;
    }

    .amountNumber {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

.svg-chart-container {
  .nodes-wrapper {
    .node {
      rect:not([stroke="none"]) {
        stroke: $success;
        stroke-width: 0px;
      }
    }
  }
}

.empInfo {
  padding: 10px;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    color: $mediumGrey;
    &:not(:last-child) {
      padding-bottom: 5px;
      border-bottom: 1px dashed $border;
    }
    &:not(:first-child) {
      padding-top: 5px;
    }
    span {
      color: $black;
    }
  }
}
.empName {
  padding: 10px 10px 0px;
  a,
  span {
    color: $black;
    font-size: 12px;
    font-weight: 600;
  }
}

.checkIcon {
  width: 14px;
  height: 14px;
  margin-left: 2px;
  background-color: $success;
  display: inline-flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 2px;
  svg {
    stroke: $white;
    width: 10px !important;
    height: 10px !important;
    stroke-width: 4px;
  }
}

// help
.helpSearch {
  max-width: 500px;
  margin: 30px auto;

  .input-group {
    button {
      height: auto;
    }
  }
}

.categoriesWrap {
  display: flex;
  flex-wrap: wrap;

  a {
    border: 1px solid rgba($black, 0.08);
    border-radius: 8px;
    padding: 35px;
    text-align: center;
    flex: 1 0 32%;

    flex-grow: 0;
    flex-shrink: 1;
    margin: 7.5px 5px;
    transition: all 0.3s;
    text-decoration: none !important;
    background-color: $white;

    &:hover {
      border: 1px solid $primary;
      transition: all 0.3s;

      h5,
      p {
        color: $primary;
      }
    }

    h5 {
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 600;
      color: $black;
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
      color: $mediumGrey;
      margin-top: 10px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}

.topicList {
  li {
    color: $black;
    font-size: 14px;
    font-weight: 500;
    &:not(:first-child) {
      padding-top: 10px;
    }
    &:not(:last-child) {
      border-bottom: 1px dashed $border;
      padding-bottom: 10px;
    }
    &:hover {
      color: $primary;
    }
  }
}

// training detail
.cardDesign {
  .card-header {
    .card-title {
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
      margin: 0;
    }
    .card-subtitle {
      font-size: 12px;
      margin-top: 2px;
    }
    svg {
      width: 20px;
      height: 20px;
      margin-left: 4px;
      stroke: $success;
      stroke-width: 4px;
    }
  }
}
.traningContent {
  .traningImageWrap {
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
    // max-height: 300px;widdt
    max-width: 450px;
    margin-bottom: 20px;
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .topicVideo {
    // height: 350px;
    max-width: 550px;
    width: auto;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    position: relative;
    video {
      width: 100%;
      height: 100%;
      margin-bottom: 0;
    }
    iframe {
      display: block;
      margin: 25px auto;
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      width: 100%;
      height: 100%;
      max-height: unset;
    }
  }

  h1 {
    font-size: 18px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    margin-bottom: 5px;
    color: rgba($black, 0.8);
  }
}

.additionalCard {
  margin-top: 20px;
  border-color: rgba($border, 0.5);
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-color: rgba($border, 0.5);
    label {
      font-size: 12px;
    }
  }
  button {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.trainingWrap {
  h2 {
    font-size: 18px;
    margin-bottom: 5px;
    color: $black;
  }
  .trainingCard {
    margin-top: 20px;
    .card {
      border-color: #ededed;
      box-shadow: 0px 10px 24px -13px rgba(0, 0, 0, 0.1);
      height: auto;
      position: relative;
      padding-bottom: 25px;
      height: 100%;
      h2 {
        font-size: 18px;
        color: $black;
        margin-bottom: 10px;
        &::first-letter {
          text-transform: capitalize;
        }
      }
      p {
        margin-bottom: 0px;
        color: rgba($black, 0.7);
      }
      .progressWrap {
        position: absolute;
        bottom: 12px;
        left: 15px;
        width: calc(100% - 32px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .progress {
          width: 100%;
        }
        .btn {
          margin-left: 10px;
          font-size: 12px;
          padding: 4px 8px;
        }
        .iconCheck {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-left: 10px;
          background-color: $success;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 12px;
            height: 12px;
            stroke: $white;
            stroke-width: 4px;
          }
        }
      }
    }
  }
}

// progress
.progress {
  .bg {
    &-success {
      background-color: $success !important;
    }
  }
}

// custom video player
.customVideoWrapper {
  position: relative;
  overflow: hidden;

  video {
    background-color: $black;
  }

  .videoControlsWrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 9px 10px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(100%);
    transition: transform 0.25s linear;
    background: linear-gradient(to top, rgba($black, 0.25), rgba($black, 0.02));
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .videoSeekBarWrap {
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    position: relative;
  }

  .videoSeekBar {
    background: $mediumGrey;
    cursor: pointer;
    width: 100%;
    height: 4px;
    border-radius: 20px;
  }

  .dragHandle {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    padding: 0;
    background: transparent;
    border: 0 !important;
    height: unset;
    position: absolute;
    top: -5px;
    left: 0;

    &::-webkit-slider-thumb {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0 1px 6px rgba($black, 0.2);
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
    }

    &::-moz-range-thumb {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0 1px 6px rgba($black, 0.2);
      cursor: pointer;
    }
  }

  .videoProgress {
    background: $primary;
    height: 100%;
    transition: all 0.3s;
    border-radius: 20px;
  }

  .videoControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    > div {
      display: flex;
      align-items: center;
    }

    .btn {
      width: 33px;
      height: 33px;
      padding: 0;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 29px;
      text-decoration: none !important;
      margin: 0 5px;
      color: $white !important;
      @include ripple;
      border-radius: 6px;
      svg {
        width: 24px;
        height: 24px;
        fill: $white;
        stroke: $white;
      }

      img {
        height: 32px;
        width: unset;
        object-fit: contain;
      }
    }
  }

  .videoTimestamp {
    font-size: 14px;
    color: $white;
    margin-left: 5px;
    font-weight: 600;
    margin-top: 3px;
  }

  .videoVolume {
    display: flex;
    align-items: center;
    position: relative;

    .dragHandle {
      width: 70px;
      top: 10px;
      right: 43px;
      left: unset;
      border-radius: 20px;
    }

    .videoSeekBar {
      width: 70px;
      display: none;
      transition: all 0.3s;
    }

    &:hover .videoSeekBar {
      display: block;
      transition: all 0.3s;
    }
  }

  &.nowPlaying {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: #111;
    font-weight: 500;
    font-size: 16px;
    border-radius: 16px;
    min-height: 300px;
  }

  .closeVideo,
  .enlargeVideo,
  .dragVideo {
    position: absolute;
    right: 7px;
    top: 5px;
    transform: translateY(-150%);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    width: 29px;
    height: 29px;
    text-align: center;
    padding: 0;
    text-decoration: none;
    border-radius: 50%;
    background-color: rgba($black, 0.4);
    transition: transform 0.25s linear;
  }

  .enlargeVideo {
    right: unset;
    left: 7px;

    img {
      height: 18px;
      width: unset;
      object-fit: contain;
    }
  }

  .dragVideo {
    cursor: grab !important;
    right: unset;
    left: 50px;
    font-size: 20px;

    &:active {
      cursor: grabbing !important;
    }
  }

  .playPauseVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    color: $white !important;
    font-size: 50px;
    z-index: 99;
    color: $white;
    padding: 0;
    text-decoration: none;
    transition: all 0.3s;

    img {
      height: 42px;
      object-fit: contain;
      width: unset;
    }
    svg {
      width: 50px;
      height: 50px;
      fill: $white;
      stroke: $white;
      filter: drop-shadow(0px 2px 4px rgba($black, 0.4));
    }
  }

  .playingVideo {
    display: none;
    transition: all 0.3s;
  }

  .videoSettings {
    position: absolute;
    bottom: 53px;
    background: $white;
    border-radius: 6px;
    right: 15px;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
  }

  &.fullscreen {
    position: fixed;
    inset: 0;
    z-index: 1031;
    max-height: unset;

    video {
      border-radius: 0;
      width: 100%;
      height: 100%;
      max-height: unset;
    }

    .videoControlsWrap {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .videoControls .btn {
      margin: 0 7px !important;
    }

    .videoVolume {
      .dragHandle {
        right: 47px;
      }
    }
  }

  &.fullscreen.landscapeVideoWrap,
  &.fullscreen.portraitVideoWrap,
  &.fullscreen.squareVideoWrap,
  &.floating.landscapeVideoWrap,
  &.floating.portraitVideoWrap,
  &.floating.squareVideoWrap {
    padding-top: 0;
  }

  .theatre {
    border-radius: 0;
  }

  &.fullscreen.landscapeVideoWrap {
    video {
      object-fit: contain;
    }
  }

  &.floating {
    position: fixed;
    z-index: 100;
    bottom: 15px;
    right: 15px;
    width: 350px !important;
    height: 196.875px !important;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);

    video {
      height: 100%;
      display: block;
      border-radius: 16px;
    }

    .videoTimestamp {
      display: none;
    }

    &:not(.react-draggable-dragging) {
      -webkit-transition: -webkit-transform 0.5s ease-out; /* Safari */
      transition: transform 0.5s ease-out;
    }
  }

  &:hover {
    .videoControlsWrap {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }

    .closeVideo,
    .enlargeVideo,
    .dragVideo {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }

    .playingVideo {
      display: flex;
      transition: all 0.3s;
    }
  }

  &:focus {
    .videoControlsWrap {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }
  }
}
// custom video player

// notification
.notificationList {
  ul {
    margin: 0;
    padding-left: 0px;
    list-style: none;
    border: 1px solid $border;
    border-radius: 10px;
    overflow: hidden;
    li {
      padding: 15px;
      padding-left: 35px;
      background-color: #ededed;
      cursor: pointer;
      border-bottom: 1px dashed $border;
      position: relative;
      .nofiticationHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0px;
        margin-top: 5px;
        h2 {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }
        span {
          font-size: 12px;
          color: rgba($black, 0.5);
        }
      }

      p {
        font-size: 14px;
        margin: 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        border-radius: 50px;
        background-color: rgba($color: $black, $alpha: 0.4);
      }
      &.newMessage {
        background-color: $white;
        &::after {
          animation: circle-pulse 2s infinite;
          box-shadow: 0 0 0 0 #4daa57;
          background-color: $success;
        }
      }
    }
  }
}

.notificationContent {
  h2 {
    font-size: 16px;
    font-weight: 600;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  p {
    margin-bottom: 5px;
  }
  span {
    font-size: 12px;
    color: $lightBlack;
    margin-bottom: 15px;
    display: block;
  }
}

// badge
.badge {
  font-weight: 500;
  font-size: 13px;
  &.bg {
    &-success {
      background-color: rgba(91, 209, 159, 0.2) !important;
      border: rgba(91, 209, 159, 0.2) !important;
      color: $success;
    }
    &-danger {
      background: rgba(255, 66, 65, 0.2) !important;
      color: $error !important;
      border-color: rgba(255, 66, 65, 0.2) !important;
    }
    &-warning {
      background: rgba(253, 185, 39, 0.2) !important;
      border-color: rgba(253, 185, 39, 0.2) !important;
      color: $warning !important;
    }
  }
}

//video quality change

.video-container {
  height: 500px;
  width: 850px;
  position: relative;
}

.videoTag {
  display: block;
  height: auto;
}

.videoQuality {
  position: absolute;
  top: 10px;
  right: 10px;
}

svg {
  width: 60px;
  height: 60px;
  stroke: $primary;
  &:not(.svg-chart-container) {
    width: auto;
    height: auto;
  }

  &#filed {
    fill: $error;
    stroke: $white;
  }
  &#success {
    fill: $success;
    stroke: $white;
  }
  &#warning {
    stroke: $warning;
    stroke: $white;
  }
}

.svg-chart-container {
  width: auto;
  height: auto;
}

.quizTestCard {
  padding-bottom: 20px;
  .cardBody {
    text-align: center;

    h3 {
      font-size: 22px;
      margin: 10px 0;
      font-weight: 600;
    }
    button {
      margin-top: 10px;
      height: auto;
      padding: 8px;
    }

    .percentage {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      margin-bottom: 10px;
      span {
        &:not(.badge) {
          font-size: 18px;
          margin-right: 5px !important;
        }
      }
    }
  }
}

.beforeWarning {
  text-align: center;
  margin: auto;

  svg {
    margin-bottom: 10px;
    stroke: $warning !important;
  }
  p {
    line-height: 1.8;
  }
}

.mediaPhotoWrap-Feed {
  video {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    max-height: 580px;
  }
}

// note: this project uses reactstrap version -> v9.
// media styles
@media all and (max-width: 1199.98px) {
  .checkboxTable {
    thead th,
    tbody td {
      &:first-child {
        min-width: unset;
      }
    }
  }
}

@media all and (max-width: 991.98px) {
  .toggleIcon {
    display: block;
  }
  .categoriesWrap {
    a {
      flex: 1 0 48%;
    }
  }
  .dashboard-analytics {
    position: unset;
    top: 0;
  }
  // filter
  .filterBarIcon {
    display: block;
    margin-left: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .filterWrapper {
    display: none;
    padding-left: 5px;
    padding-right: 5px;

    &.showFilter {
      display: block;
    }

    .filterIcon {
      display: none;
    }

    .filterForm {
      padding: 0;
      margin-left: 0;
      justify-content: flex-start;

      .formGroup {
        width: 33.3333%;
        flex: auto;
        flex-grow: 0;
        max-width: inherit !important;
      }

      &::before {
        display: none;
      }
    }
  }

  .statusList {
    display: inherit;
    align-items: unset;
    overflow-x: visible;

    li {
      width: 270px;
      display: flex;
      align-items: center;

      span {
        width: 120px;
        position: relative !important;

        &.status {
          padding-right: 15px;
          text-align: right;
        }

        &.date {
          padding-left: 15px;
        }
      }

      &::before {
        left: 50%;
        top: 80px;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
      }
    }
  }

  .personalWebsiteSetps {
    .nav {
      width: 100%;
      .nav-item {
        .nav-link {
          .stepCount {
            width: 30px;
            height: 30px;
            svg {
              width: 16px;
              height: 16px;
            }
          }
          span {
            &.stepTitle {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 767.98px) {
  .filterWrapper {
    .filterForm {
      justify-content: space-between;

      .formGroup {
        width: 49%;
        margin-right: 0;
        margin: 0 0 15px;
      }
    }
  }

  .billingSummaryWrap {
    .billingSummary {
      ul {
        li {
          flex: 1 0 45%;
        }
      }
    }
  }
}

@media all and (max-width: 575.98px) {
  .filterWrapper {
    .filterForm {
      .formGroup {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .paginationWrap {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;

    .showng_entries {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }

    .pagination {
      width: 100%;
      margin: auto;
      margin-top: 20px;
      justify-content: center !important;

      li.previous {
        a {
          margin-left: 0;
        }
      }
    }
  }

  .billingSummaryWrap {
    .billingSummary {
      ul {
        li {
          flex: 1 0 100%;
          margin: 0 0 20px;
        }
      }
    }
  }

  .pgTitleWrap {
    h2 {
      font-size: 14px;
    }

    .right {
      .btn {
        padding: 3px;

        span {
          display: none;
        }

        .plusIcon {
          display: block;

          svg {
            width: 18px;
            height: 18px;
            stroke: $white;
          }
        }
      }
    }
  }

  .personalInfo {
    .contactInfo {
      ul {
        width: 100%;
      }
    }
  }
  .projectFooter {
    justify-content: center;
    ul {
      width: 100%;
      margin-top: 10px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .personalWebsiteSetps {
    .nav {
      .nav-item {
        .nav-link {
          &::before,
          &::after {
            display: none !important;
          }
        }
      }
    }
  }

  .docSignStatus {
    min-height: calc(100vh - 56px);

    img {
      width: 30px;
    }
  }

  .acceptAgreementBox {
    .title {
      font-size: 22px;
    }

    .sub-title {
      font-size: 19px;
    }
  }
  .checkIcon {
    width: 12px;
    height: 12px;
    svg {
      width: 8px !important;
      height: 8px !important;
    }
  }
  .customVideoWrapper {
    .videoVolume {
      display: none;
    }
    .videoControls {
      .btn {
        font-size: 18px;
        img {
          width: 28px;
        }
      }
    }
    .playPauseVideo {
      svg {
        width: 30px;
      }
    }
  }
}
